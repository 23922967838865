
/* Icons */

.Back-icon {
  height: 4em;
  cursor: pointer;
}

@media(max-width: 674px) {
  .Back-icon {
    height: 2.5em;
  }
}

/* Global styles */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

html {
  background-color: #FEFFE4;
}

.App {
  position: absolute;
  top: 0;
  left: 0;
  font-family: 'Meera Inimai', sans-serif;
  font-family: 'Open Sans Condensed', sans-serif;
  max-width: 100vw;
}

.Heading {
  margin-bottom: 3%;
}

.Button {
  font-family: 'Meera Inimai', sans-serif;
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 1rem;
  border: none;
  color: black;
  font-weight: bold;
  background-color: #A3DE83;
}

.Loading-animation {
  margin-top: 3em;
  max-width: 10em;
  opacity: 70%;
}

.Logout-button {
  background-color: #A3DE83;
}

@media(max-width:447px) {
  .Logout-button {
    font-size: .8rem;
  }
  .UpperNavBar-item {
    padding-left: .4em;
  }
}

.Button:hover {
  box-shadow: 5px 6px
}

.no-underline {
  text-decoration: none;
}

.Footer {
  position: fixed;
  width: 100vw;
  background-color: white;
  text-align: center;
  bottom: 0;
}

.Footer-img {
  height: 2.3em;
}

.Shadow-footer {
  height: 100px;
  width: 100%;
}

.Body {
  margin-top: 3em;
  margin-bottom: 70px;
}

.Inner-Container {
  text-align: center;
  margin: 0 auto;
  max-width: 80%;
  animation: fadeIn 500ms ease-in;
}

.Inner-Container-jasenhakemus {
  text-align: center;
  margin: 0 auto;
  max-width: 70%;
}

.Error, .Success {
  margin: 10px 0px;
  padding:12px;
  text-align: center;
}

.Error {
  background-color: #FFD2D2;
  color: #D8000C;
}

.Success {
  color: #4F8A10;
  background-color: #DFF2BF;
}

.UpperNavBar {
  width: 100vw;
  background-color: #3A835D;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
}

.UpperNavBar-item {
  display: inline-block;
  vertical-align: middle;
  margin-left: 2%;
  color: #feffe4;
  cursor: pointer;
}

.UpperNavBar-button {
  height: 40px;
  cursor: pointer;
}

.UpperNavBar-languages {
  display: flex;
  width: 15em;
  justify-content: space-evenly;
}

.UpperNavBar-right-side {
  display: flex;
  justify-content: space-evenly;
  width: 12em;
  align-items: baseline;
}

.UpperNavBar-right-side-loggedIn {
  display: flex;
  justify-content: space-evenly;
  width: 30em;
  align-items: center;
}

.UpperNavBar-separator {
  display: inline-block;
  vertical-align: middle;
  margin-left: 2%;
  color: #373737;
}

.UpperNavBar-item:hover,
.UpperNavBar-item:focus,
.UpperNavBar-item:active {
  transform: scale(1.1);
}

.right {
  float: right;
  margin-right: 2.3%;
}

.left {
  float: left;
  margin-left: 2.3%;
}

.LowerNavBar {
  width: 100vw;
  height: 80px;
  background-color: #a3de83;
  font-size: 1.3rem;
}

.LowerNavBar-logo {
  height: 70px;
  cursor: pointer;
}

.LowerNavBar-LSS {
  margin-left: 1%;
  color: #373737;
  cursor: pointer;
}

.LowerNavBar-navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
}

.LowerNavBar-item {
  color: black;
  text-decoration: none;
  display: inline-block;
  padding: 1rem;
}

.LowerNavBar-item:hover,
.LowerNavBar-item:active {
  color: #373737;
  cursor: pointer;
}

.LowerNavBar-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

@media(max-width:447px) {
  .UpperNavBar {
    font-size: .9rem;
  }
  .UpperNavBar-separator {
    margin-left: 0%;
    transform: translateX(-.6em);
  }
  .UpperNavBar-item {
    margin-left: 0%;
    transform: translateX(-.6em);
  }
  .LowerNavBar-logo {
    height: 50px;
  }
  .LowerNavBar-LSS {
    font-size: 1rem;
  }
}

.Spacer {
  flex: 1;
}

.Toggle-Button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
}

.Toggle-Button:focus {
  outline: none;
}

.Toggle-Button-line {
  width: 40px;
  height: 3px;
  background: #353130;
}

.Side-Drawer {
  height: 100vh;
  background: #BF4D49;
  box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.Side-Drawer.open {
  transform: translateX(0);
}

.Side-Drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Side-Drawer-item {
  color: #FEFFE4;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: calc(15px + 1vmin);
  padding: 1rem;
}

.Side-Drawer-item:hover, 
.Side-Drawer-item:active {
  color: #373737;
}

.Side-Drawer li {
  margin: 0.5rem 0;
}

.Backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
}

@media (max-width: 1151px) {
  .LowerNavBar-items {
    display: none;
  }
}

@media (min-width: 1152px) {
  .Toggle-Button {
    display: none;
  }
}

/* Login */

.Login-container {
  font-size: 1.3rem;
}

.Login-Input {
  height: 50px;
  font-size: large;
}

.Login-Button {
  margin-top: 2%;
  padding: 1em;
  width: 5em;
}

.Unohdin-salasanani {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.reset-password-block {
  margin-top: 5%;
}

/* ResetPassword */

.ResetPassword-container {
  font-size: 1.3rem;
}

/* Etusivu */

.Etusivu-flexbox-outer {
  margin-top: 5em;
  display: flex;
  justify-content: space-evenly;
}

.Etusivu-flexbox-inner {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  justify-content: flex-start;

}

.Etusivu-text-box {
  text-align: left;
  font-size: 1.5rem;
  width: 50%;
  margin-bottom: 1.5em;
}

.Background-animation {
  width: 25em;
  height: 25em;
  margin-left: 1.5em;
}

.Etusivu-twitter-container {
  width: 27em;
  margin-top: 3em;
  margin-left: 14em;
}

.Etusivu-facebook-container {
  width: 25em;
  margin-top: 3em;
  margin-left: 1em;
}

.Etusivu-uusimmat {
  margin-top: 3em;
  width: 100%;
  text-align: left;
}

.Etusivu-uusimmat-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Etusivu-filler {
  display: none;
}

@media(max-width:1679px) {
  .Etusivu-twitter-container {
    margin-left: 1em;
  }

}

@media(max-width: 1459px) {
  .Etusivu-filler {
    width: 100%;
    display: block;
  }
}

@media(max-width: 1179px) {
  .Background-animation {
    height: 20em;
    width: 20em;
  }
}

@media(max-width: 1069px) {
  .Etusivu-flexbox-inner {
    justify-content: center;
  }
  .Etusivu-uusimmat {
    text-align: center;
  }
}

@media(max-width: 859px) {
  .Background-animation {
    height: 15em;
    width: 15em;
  }
}

@media(max-width: 533px) {
  .Etusivu-text-box {
    font-size: 1.2rem;
  }
}

@media(max-width: 659px) {
  .Background-animation {
    display: none;
  }
  .Etusivu-text-box {
    width: 90%;
  }
  .NewestProject {
    font-size: .8rem;
  }
  .NewestPublication {
    font-size: .8rem;
  }
  .NewestPublicOpinion {
    font-size: .8rem;
  }
  .NewestEvent {
    font-size: .8rem;
  }
}

@media(max-width: 401px) {
  .Etusivu-facebook-container {
    transform: translateX(-1.1em);
    margin-left: 2em;
  }
  .Etusivu-twitter-container {
    transform: translateX(-1em);
  }
  .Etusivu-text-box {
    margin-bottom: 0em;
  }
  .Etusivu-uusimmat {
    margin-top: 0em;
  }
}

/* Yhdistyksestä */

.Yhdistyksesta-container {
  font-size: 1.2rem;
  text-align: center;
  margin: 0 auto;
  animation: fadeIn 500ms ease-in;
}

.Yhdistyksesta-heading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-3em);
}

.Yhdistyksesta-heading-grid {
  display: grid;
  grid-template-columns: 2em 2em;
  grid-template-rows: 2em 2em;
  row-gap: .5em;
  margin-left: 1em;
}

.Yhdistyksesta-heading-grid-item {
  align-self: center;
}

.Yhdistyksesta-social-logo {
  width: 1.5em;
}

.Yhdistyksesta-flex-outer {
  margin-top: 1em;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.Yhdistyksesta-left-div {
  width: 14em;
  text-align: left;
  margin-left: 2em;
  border-left: 2px solid #3A835D;
  padding-left: 1em;
}

.Yhdistyksesta-center-div-inner {
  width: 80%;
  text-align: left;
  margin-left: 3em;
}

.Yhdistyksesta-center-div {
  width: 50em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-left: .5em;
}

.Yhdistyksesta-right-div {
  width: 20em;
  text-align: left;
  margin-left: 3em;
  font-size: 1rem;
  line-height: 1.3rem;
}

.Yhdistyksesta-hae-jaseneksi-button {
  padding: 1em;
}

@media (max-width: 1644px) {
  .Yhdistyksesta-right-div {
    display: block;
    margin-top: 2em;
  }
}

@media (max-width: 1336px) {
  .Yhdistyksesta-center-div {
    width: 80%;
  }
  .Yhdistyksesta-right-div {
    font-size: 1.3rem;
    line-height: normal;
  }
}

@media (max-width: 806px) {
  .Yhdistyksesta-left-div {
    font-size: 1rem;
  }
  .Yhdistyksesta-right-div {
    font-size: 1.1rem;
  }
}

@media(max-width:447px) {
  .Yhdistyksesta-heading-container {
    font-size: .9rem;
    transform: translateX(-4em);
  }
  .Yhdistyksesta-center-div {
    transform: translateX(-.5em);
  }
  .Yhdistyksesta-center-div-inner {
    transform: translateX(-1.8em);
    width: 85%;
  }
  .Yhdistyksesta-right-div {
    transform: translateX(-1em);
    font-size: 1rem;
  }
}

/* Jäsenhakemus */

.Jasenhakemus-container {
  font-size: 1.2rem;
  display: flex;
  flex-wrap: wrap;
}

/* Julkaisut */

.Julkaisut-container {
  font-size: 1.2rem;
  margin-left: 9em;
}

.Julkaisut-heading-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  margin-top: 5em;
  margin-bottom: 2em;
}

.Julkaisut-category-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.Publication-icon {
  width: 3em;
  margin-left: 1em;
}

.NewestPublication-icon {
  width: 3em;
  margin-top: .7em;
}

.Publication-category-box {
  width: 7em;
  height:4em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  font-size: 2rem;
  margin-right: 1em;
  margin-top: .5em;
}

.Publication-category-box:hover {
  box-shadow: 4px 1px 25px -5px rgba(0,0,0,0.47);
}

.Publication-category {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 4.6em;
}

.Publication-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.Publication-name-box {
  width: 7em;
  height:4em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  font-size: 2rem;
  margin-right: 1em;
  margin-top: .5em;
}

.Publication-name-box:hover {
  box-shadow: 4px 1px 25px -5px rgba(0,0,0,0.47);
}

.Publication {
  text-align: left;
  font-size: 1.5rem
}

.NewestPublication {
  margin-right: 4em;
  width: 20em;
  height:11em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  margin-right: 1em;
  margin-top: .5em;
}

.NewestPublication:hover {
  box-shadow: 4px 1px 25px -5px rgba(0,0,0,0.47);
}

.Publication-year {
  transform: translateY(-1.1em);
}

.Publication-description {
  width: 60%;
}

.Publication-file {
  font-size: 1.4rem;
}

@media(max-width: 1239px) {
  .Publication-description {
    width: 80%;
  }
}

@media(max-width: 854px) {
  .Julkaisut-container {
    margin-left: 1.5em;
  }
}

@media(max-width:447px) {
  .Julkaisut-heading-div {
    font-size: 1rem;
  }
  .Publication {
    transform: translateY(-1em);
  }
  .Publication-category {
    transform: translateX(-2.5em);
  }
  .Publication-name-box {
    margin-right: .5em;
  }
  .Publication-description {
    width: 100%;
    font-size: 1.1rem;
  }
}

@media(max-width: 674px) {
  .Publication-category-box {
    font-size: 1rem;
  }
  .Publication-name-box {
    font-size: 1rem;
  }
  .Publication-category {
    margin-left: 3em;
  }
  .Julkaisut-container {
    margin-left: 0em;
  }
  .Publication-file {
    font-size: 1rem;
  }
}

/* Tapahtumat */

.Tapahtumat-container {
  margin-left: 10em;
}

.Event-icon {
  width: 5em;
  margin-left: 1em;
}

.NewestEvent-icon {
  width: 3.5em;
  margin-top: .5em;
}

.Tapahtumat-heading-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  margin-top: 5em;
  margin-left: 1.5em;
  margin-bottom: 2em;
}

.Tapahtumat-title {
  margin-bottom: 2em;
  width: 100%;
  transform: translateX(-20%);
}

.Tapahtumat-year-box {
  width: 7em;
  height:4em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  font-size: 3rem;
  margin-right: 1em;
  margin-top: .5em;
}

.Tapahtumat-year-box:hover {
  box-shadow: 4px 1px 25px -5px rgba(0,0,0,0.47);
}

.Tapahtumat-years-div {
  display: flex;
  flex-wrap: wrap;
  margin-left: 2em;
}

@media(max-width: 1198px) {
  .Tapahtumat-container {
    margin-left: 0em;
  }
}

@media(max-width:999px) {
  .Tapahtumat-year-box {
    font-size: 2.3rem;
  }
}

@media(max-width:447px) {
  .Tapahtumat-heading-div {
    font-size: 1rem;
  }
  .Tapahtumat-year-box {
    font-size: 1rem;
  }
  .Event-right {
    font-size: .8rem;
  }
}

/* Hankkeet */

.Hankkeet-container {
  font-size: 1.3rem;
  animation: fadeIn 500ms ease-in;
  margin-left: 8em;
}

.Hankkeet-heading-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* Lausunnot */

.Lausunnot-container {
  font-size: 1.3rem;
  animation: fadeIn 500ms ease-in;
  margin-left: 8em;
}

.Lausunnot-heading-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Lausunnot-heading-div

/* Member application */

.Header-hakemus {
  text-align: left;
  margin-top: 8%;
  margin-left: 7%;
}

.Hakemus-Button {
  margin-top: 5%;
  margin-right: 20%;
  width: 100px;
  height: 50px;
}

.hakemus-li {
  margin-top: 10%;
  font-size: large;
  text-align: left;
}

.Hakemus-container-left {
  margin-top: 1%;
  width: 30em;
}

.Hakemus-container-right {
  margin-top: 3%;
  width: 30em;
  text-align: left;
}

.hakemus-form {
  margin-top: 25%;
  margin-right: 10%;
}

.Hakemus-header {
  text-align: left;
}

.hakemus-input {
  height: 40px;
  font-size: large;
  width: 85%;
  margin-top: 1%;
}

.etunimi {
  width: 120px;
  margin-right: 2%;
}

.sukunimi {
  width: 55%;
}

.hakemus-inline {
  margin: 0 auto;
}

.hakemus-label {
  margin-top: 3%;
}

.hakemus-block {
  margin-top: 4%;
}

.hakemus-send {
  margin-top: 20%;
}

.tietosuoja-container {
  display: flex;
  flex-wrap: nowrap;
}

.tietosuoja-container-left {
  width: 7%;
}

.tietosuoja-container-right {
  text-align: left;
  width: 93%;
}

@media(max-width:1439px) {
  .Jasenhakemus-container {
    justify-content: center;
  }
  .Hakemus-container-left {
    transform: translateX(-3.5em);
    margin-bottom: 2em;
  }
  .Header-hakemus {
    transform: translateX(-2.7em);
  }
}

@media(max-width:713px) {
  .Header-hakemus {
    transform: translateX(0em);
  }
}

@media(max-width:447px) {
  .Jasenhakemus-container {
    justify-content: center;
  }
  .hakemus-form {
    width: 15em;
  }
  .tietosuoja-container {
    margin-left: 1em;
  }
  .tietosuoja-container-right {
    font-size: 1rem;
    width: 18em;
    margin-left: .5em;
  }
  .Hakemus-container-left {
    transform: translateX(-2em);
    margin-bottom: 2em;
  }
  .Header-hakemus {
    transform: translateX(0em);
  }
}

/* My data view */

.Omat-tiedot {
  cursor: pointer;
}

.Omat-tiedot-header {
  margin-bottom: 6%;
}

.Edit-icon {
  width: 40px;
  float: right;
  cursor: pointer;
}

.Omat-tiedot-button {
  margin-top: 3%;
  float: left;
  height: 40px;
  margin-bottom: 4em;
  width: 6em;
  height: 3em;
}

.Omat-tiedot-container-outer {
  margin-top: 3%;
  margin-left: 7%;
  width: 60%;
  text-align: center;
  margin-bottom: 5%;
}

.Omat-tiedot-container-edit-outer {
  margin-top: 3%;
  margin-left: 7%;
  width: 60%;
  text-align: center;
  margin-bottom: 10%;
}

.Omat-tiedot-container-inner {
  margin-top: 3%;
  text-align: left;
  margin-left: 44%;
  font-size: 1.1rem;
}

.Muokkaa-tietoja-container-outer {
  margin-top: 3%;
  margin-left: 7%;
  width: 60%;
  text-align: center;
  margin-bottom: 10%;
}

.Muokkaa-tietoja-container-inner {
  margin-top: 3%;
  text-align: left;
  margin-left: 44%;
}

.Omat-tiedot-block {
  margin-top: 3%;
  border-bottom: 1px solid #373737;
}

.Omat-tiedot-edit-block {
  margin-top: 3%;
}

.Omat-tiedot-input {
  height: 50px;
  width: 450px;
  font-size: large;
  margin-top: 1%;
}

.Omat-tiedot-item {
  font-size: larger;
}

@media(max-width:447px) {
  .Omat-tiedot-header {
    margin-bottom: 1em;
  }
  .Edit-icon {
    width: 1.5em;
  }
  .Omat-tiedot-container-inner {
    margin-left: 0em;
  }
  .Omat-tiedot-block {
    font-size: .9rem;
    margin-bottom: 2.5em;
  }
  .Muokkaa-tietoja-container-inner {
    margin-left: 0em;
  }
  .Omat-tiedot-button {
    margin-top: 1.5em;
    margin-bottom: 5em;
    width: 6em;
    height: 3em;
  }
  .Omat-tiedot-input {
    width: 20em;
    font-size: .8rem;
  }
}

/* Events view */

.EventsByYear-container {
  display: flex;
  justify-content: center;
}

.EventsByYear-heading-div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 3em;
  margin-bottom: 2em;
}

.Event {
  width: 100%;
  max-width: 40em;
  text-align: left;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #3A835D;
}

.Event-date {
  transform: translateY(-1em);
}

.NewestEvent {
  margin-right: 4em;
  width: 20em;
  height:11em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  margin-right: 1em;
  margin-top: .5em;
}

.NewestEvent:hover {
  box-shadow: 4px 1px 25px -5px rgba(0,0,0,0.47);
}

.Event-left {
  width: 50%;
}

.Event-right {
  width: 50%;
  margin-left: 1em;
}

.Event-image {
  max-width: 100%;
}

.Event-time {
  transform: translateY(1em);
  font-size: 2rem;
}

.Event-name {
  transform: translateY(-1em);
  font-size: 2rem;
}

.Event-speakers {
  transform: translateY(1em);
}

@media(max-width: 559px) {
  .Event-time {
    font-size: 1.5rem;
  }
  .Event-name {
    font-size: 1.5rem;
  }
}

@media(max-width: 424px) {
  .Event-time {
    font-size: 1.1rem;
  }
  .Event-name {
    font-size: 1.1rem;
  }
}

/* ProjectList view */

.ProjectList {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.ProjectList-box {
  width: 7em;
  height:4em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  font-size: 2rem;
  margin-right: 1em;
  margin-top: .5em;
}

.ProjectList-box:hover {
  box-shadow: 4px 1px 25px -5px rgba(0,0,0,0.47);
}

.Project {
  margin-top: 4em;
  text-align: left;
  margin-left: 4em;
}

.NewestProject {
  margin-right: 4em;
  width: 20em;
  height:11em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  margin-right: 1em;
  margin-top: .5em;
}

.NewestProject:hover {
  box-shadow: 4px 1px 25px -5px rgba(0,0,0,0.47);
}

.Project-icon {
  width: 2.4em;
  margin-left: 1em;
}

.NewestProject-icon {
  width: 2.4em;
  margin-top: .5em;
  margin-left: 1em;
}

.Project-year {
  transform: translateY(-1.1em);
}

.Project-description {
  font-size: 1.5rem;
  width: 60%; 
}

.Project-filename {
  font-size: 1.2rem;
}

@media(max-width: 1134px) {
  .Hankkeet-container {
    margin-left: 2.5em;
  }
  .Project-description {
    width: 90%;
  }
}

@media(max-width: 740px) {
  .ProjectList-box {
    font-size: 1.1rem;
  }
}

@media(max-width: 660px) {
  .Hankkeet-container {
    margin-left: 0em;
  }
  .Project-description {
    width: 90%;
    font-size: 1rem;
  }
}

@media(max-width:447px) {
  .Hankkeet-heading-div {
    font-size: 1rem;
  }
  .Project {
    margin-left: .8em;
    margin-top: 0em;
  }
  .Project-filename {
    font-size: .9rem;
  }
}

/* PublicOpinionList */

.PublicOpinionList {
  margin-top: 3em;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.PublicOpinionList-box {
  width: 7em;
  height:4em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  font-size: 2rem;
  margin-right: 1em;
  margin-top: .5em;
}

.PublicOpinionList-box:hover {
  box-shadow: 4px 1px 25px -5px rgba(0,0,0,0.47);
}

.PublicOpinion-icon {
  width: 2.4em;
  margin-left: 1em;
}

.NewestPublicOpinion-icon {
  width: 2.4em;
  margin-top: .8em;
}

.PublicOpinion {
  text-align: left;
  margin-left: 4.3em;
}

.PublicOpinion-file {
  font-size: 1.1rem;
}

.PublicOpinion-request-file {
  font-size: 1.1rem;
}

.NewestPublicOpinion {
  margin-right: 4em;
  width: 20em;
  height:11em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  margin-right: 1em;
  margin-top: .5em;
}

.NewestPublicOpinion:hover {
  box-shadow: 4px 1px 25px -5px rgba(0,0,0,0.47);
}

.PublicOpinion-date {
  transform: translateY(-1.3em);
}

@media(max-width: 1056px) {
  .Lausunnot-container {
    margin-left: 1em;
  }
}

@media(max-width: 664px) {
  .PublicOpinionList-box {
    font-size: 1rem;
  }
}

@media(max-width:447px) {
  .Lausunnot-heading-div {
    font-size: 1rem;
    transform: translateX(-1em);
  }
  .PublicOpinion {
    margin-left: 1em;
    transform: translateX(-1.4em);
  }
  .PublicOpinion-file {
    font-size: 1rem;
  }
  .PublicOpinion-request-file {
    font-size: 1rem;
  }
}
